@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
.logo {
  width: 200px;
}
.Form_nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.Vendor-click {
  background-color: rgba(0, 0, 0, 0.721);
  transition: 250ms;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 10px;
}
.Vendor-click:hover {
  opacity: 0.4;
}
.Form_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 80vh;
  /* margin-top: 100px; */
  padding: 20px;
}
.button:hover {
  background-color: #b32f34;
}
.Head {
  font-weight: bold;
  font-size: 30px !important;
}
.lottiefiles {
  width: 500px;
  height: 300px;
}
.continueButton {
  border: none;
  color: white;
  font-family: var(--font);
  text-transform: capitalize;
  background-color: var(--default-color);
  padding: 20px 40px;
  font-weight: bold;
  border-radius: 20px;
  font-size: 18px;
}
.Name {
  text-align: center;
  font-size: 14px;
  opacity: 0.4;
  font-family: var(--font);
  width: 200px;
  font-weight: bold;
  margin-bottom: 10px;
}
@media screen and (max-width: 848px) {
  .form-group {
    margin-left: 10px;
  }
  .lottiefiles {
    width: 380px;
    height: 400px;
  }
  .Head {
    font-size: 20px !important;
  }
}
