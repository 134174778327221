:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.WishList-body {
  position: relative;
  top: 100px;
}
.Whilst_container {
  padding: 0 30px;
  margin-bottom: 50px;
}
.wishlist-head {
  font-weight: 800;
  font-size: 25px;
  font-family: var(--font);
  color: var(--text-color);
}
.back {
  background-color: #1c1c1c0a;
  height: 50px !important;
  width: 50px !important;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  transition: 250ms;
}
.back:hover {
  transform: rotate(180deg);
  background-color: #1c1c1c15;
}
.wishListAmount {
  font-weight: bold;
  font-family: var(--font);
  color: var(--text-color);
  opacity: 0.8;
  padding: 20px;
}
.title {
  font-family: var(--font);
  font-weight: bold;
  color: var(--text-color);
  opacity: 0.9;
}
.category {
  font-family: var(--font);
}
.Old_price {
  font-weight: bold;
  font-family: var(--font);
}
.delete {
  padding: 10px 0;
  border-radius: 50px;
  width: 45px;
  transition: 250ms;
}
.delete:hover {
  background-color: rgba(0, 0, 0, 0.094);
}
@media screen and (max-width: 550px) {
  .title {
    font-size: 0.7em;
  }
}
