.trending-container {
  display: flex;
  width: 100%;
  overflow-x: auto; /* Ensure horizontal scroll */
  gap: 15px;
  padding: 20px;
  justify-content: flex-start; /* Align items to the start */
  scrollbar-width: none; /* Hide the scrollbar */
  margin-top: 10px;
}

.Trending_Product_container {
  position: relative;
  /* border: 1px solid rgba(0, 0, 0, 0.349) !important; */
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.05) !important;
  width: 220px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  transition: 250ms;
}

.Trending_Product_container:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.rating {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.rating span {
  font-weight: bold;
  margin-left: 5px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  margin: 10px 0;
}

.image-container img {
  max-width: 100%;
  max-height: 100%;
}

.product-name {
  font-family: inter, sans-serif !important;
  /* font-weight: 600; */
  font-size: 14px;
  width: 170px;
  margin-top: 20px;
}

.price-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.price {
  font-weight: bold;
  font-size: 18px;
}

.add-to-cart {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .Trending_Product_container {
    width: 190px;
    flex: 0 0 45%; /* Two items fit within the container */
  }
  .trending-container {
    justify-content: start;
  }
}
