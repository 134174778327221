:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
.input-container {
  background-color: rgba(0, 0, 0, 0.078);
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 350px;
  border-radius: 20px;
}
.accordion-button {
  font-weight: 600;
  font-family: var(--font);
}
.inputBx {
  background-color: transparent;
}
