:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
.checkBox-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  color: orangered;
  font-weight: 600;
  .process-text {
    text-align: center;
    font-size: 10px;
    text-transform: capitalize;
    opacity: 0.5;
  }
  .line-through {
    width: 70px;
    height: 1px;
    border-radius: 20%;
    margin-bottom: 10px;
    background-color: orangered;
  }
  .process-number {
    background-color: orangered;
    color: white;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  }
}
.order-summary-body {
  padding: 30px 120px;
  .order-summary-header {
    font-size: 15px;
    padding: 10px;
    font-family: var(--font);
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.221);
  }
  .order-summary-bd {
    padding: 10px;
    .date {
      font-weight: bold;
      font-size: 13px;
      font-family: var(--font);
    }
    .delivery-details {
      margin-top: 10px;
      font-family: var(--font);
      text-transform: capitalize;
      font-weight: light;
      font-size: 12px;
    }
    .delivery-price {
      font-weight: bold;
      font-family: var(--font);
      margin-top: 10px;
    }
    .order-company {
      font-size: 10px;
      font-family: var(--font);
      opacity: 0.5;
      margin-top: 20px;
    }
    .info {
      background-color: rgb(235 240 244);
      color: rgb(93, 138, 205);
      width: 200px;
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 10px;
    }
  }
  .order-id {
    margin-top: 10px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: bold;
  }
  .order-summary-product {
    margin-top: 15px;
    .order-summary-items {
      padding: 10px;
      font-family: var(--font);
      border-bottom: 1px solid rgba(0, 0, 0, 0.221);
      display: flex;
      justify-content: space-between;
      .order-summary-image {
        width: 100px;
      }
      .order-category {
        font-size: 11px;
        font-weight: bold;
        font-family: var(--font);
        text-transform: capitalize;
      }
      .order-name {
        font-size: 14px;
      }
    }
  }
  .order-address {
    padding: 10px;
    font-size: 14px !important;
  }
  .bank-icn {
    border: 1px solid green;
    padding: 10px;
    border-radius: 10px;
  }
  .transfer-type {
    text-transform: capitalize;
    font-weight: bold;
  }
  .pricing-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.094);
    padding: 10px;
    .price-item-name {
      font-family: var(--font);
    }
    .price-item {
      font-weight: bold;
      font-family: var(--font);
    }
  }
  .pricing-details {
    border-radius: 4px;
    padding: 10px;
  }
  .link {
    width: 100%;
    border: 1px solid var(--default-color);
    margin-top: 10px;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--default-color);
    font-family: var(--font);
    text-transform: capitalize;
    transition: 250ms;
    cursor: pointer;
    text-decoration: none;
  }
  .link:hover {
    background-color: var(--default-color);
    color: white;
  }
}
@media screen and (max-width: 768px) {
  .order-summary-body {
    padding: 20px;
  }
}
