:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.footer {
  background-color: var(--default-color);
  color: #fff !important;
  padding-bottom: 10px !important;
  width: 100%;
}
.footer-body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  padding: 20px;
  margin-top: 40px;
}
.footer-header {
  font-weight: 900;
  font-size: 30px;
  font-family: var(--font);
}
.written {
  width: 400px;
  font-family: var(--font) !important;
  font-weight: 500 !important;
}
.footer .footer_disc {
  width: 400px;
}

.footer ul li:hover {
  background-color: #bd5559;
  padding: 0;
}
.footer .loc {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: var(--font);
  font-weight: 600;
}
.iconsbdy {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #fff;
  font-size: 1.7em;
}
.iconsbdy .icons-itm {
  background-color: #fff;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: black;
  cursor: pointer;
  transition: 0.2s;
}
.icons-itm:hover {
  transform: scale(1.3) !important;
}

.copy-write {
  text-align: center;
  font-family: var(--font);
}

@media screen and (max-width: 768px) {
  .footer {
    margin-bottom: 0px;
    font-size: 14.5px;
    padding: 1px 1px 20px 0 !important;
  }
  .footer ul {
    margin-top: 40px;
    display: flex;
    align-items: start;
    width: 100%;
    flex-direction: column;
    gap: 1px;
  }
  .footer .loc {
    margin-top: 20px;
  }
  .written {
    font-size: 15.25px !important;
  }
}
@media screen and (max-width: 450px) {
  .written {
    width: 350px !important;
  }
}
