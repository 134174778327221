.ArrowBody {
  position: fixed !important;
  bottom: 20px;
  background-color: #a4161ba7;
  transition: 250ms;
  color: white !important;
  right: 10px;
  cursor: pointer;
  z-index: 2;
  padding: 15px 20px;
  box-shadow: 6px 6px 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 55%;
  font-size: 20px;
}
.ArrowBody:hover {
  background-color: #a4161b;
}

@media screen and (max-width: 768px) {
  .ArrowBody {
    position: fixed !important;
    bottom: 80px;
    right: 10px;
    z-index: 2;
    padding: 15px 20px;
    box-shadow: 6px 6px 10px -4px rgba(0, 0, 0, 0.5);
    border-radius: 100%;
  }
}
