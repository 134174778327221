:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
.breadcrumbs {
  padding: 10px 20px;
  color: var(--text-color);
  font-family: var(--font);
  text-transform: capitalize;
  font-weight: 600;
}
.headercontact {
  font-weight: bold;
  font-size: 27px;
  text-align: center;
  color: var(--text-color);
  font-family: var(--font);
}
.form-input {
  overflow: scroll !important;
  scrollbar-width: none;
}
.form-container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;
  padding: 0 !important;
}
.form-body {
  margin-top: 20px;
}
.payment-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.Payment-icn {
  background-color: #00000018;
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-top: 40px;
}
.account {
  background-color: #00000018;
  padding: 10px;
  margin-bottom: 10px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-information-cont {
  margin-top: 80px;
}
.payment-receipt {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload-area {
  height: 200px;
  width: 500px;
  border: 3px dotted rgba(0, 0, 0, 0.338);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.review-form {
  height: 100% !important;
}
.item-submit-btn {
  padding: 10px 40px;
  transition: 250ms;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  font-family: var(--font);
  background-color: #0000001e;
}
.item-submit-btn-active {
  padding: 10px 40px;
  transition: 250ms;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  font-family: var(--font);
  background-color: var(--default-color);
}
@media screen and (max-width: 768px) {
  .headercontact {
    margin-top: 30px;
  }
  .review-form {
    width: 100% !important;
  }
  .form-body {
    margin-top: 1px;
    padding: 20px;
    width: 100% !important;
  }
  .upload-area {
    width: 350px;
  }
}
@media screen and (max-width: 540px) {
  .account {
    width: 350px;
  }
}
@media screen and (max-width: 420px) {
  .account {
    width: 300px;
  }
}
