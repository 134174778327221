:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.pageBody {
  position: relative;
  top: 120px;
}
.badger {
  background-color: #1c1c1c0a;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
  transition: 250ms;
}
.badger:hover {
  transform: rotate(180deg);
  background-color: #1c1c1c15;
}
.YourOrder {
  background-color: #a4161a;
  height: 10px;
  border-radius: 40px;
  width: 100px;
}
.YourOrder2 {
  background-color: #deddd8;
  height: 10px;
  border-radius: 40px;
  width: 100px;
}
.ordersummary {
  width: 70%;
  font-family: var(--font);
}
.orderbody {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  color: var(--text-color);
  font-weight: bold;
  font-family: var(--font);
  text-align: center;
}
.details {
  width: 100%;
  background-color: #1c1c1c0a;
  padding: 15px 20px;
  margin-top: 20px;
  font-weight: 700;
  text-transform: capitalize;
}
.trashIcn {
  background-color: #1c1c1c15;
  width: 40px;
  border-radius: 100%;
  text-align: center;
  padding: 2px 0 10px 0;
  color: black !important;
  transition: 0.3s;
  cursor: pointer;
  font-size: 14px;
}
.trashIcn:hover {
  background-color: #1c1c1c25;
}
.cartItms {
  display: flex;
  align-items: center;
  padding: 30px;
  font-family: var(--font);
  justify-content: space-between;
  /* margin-top: 30px; */
}
.cartItms img {
  width: 50px;
}
.cartItms .img .name {
  text-transform: uppercase;
  color: #1c1c1c80;
  font-weight: 500;
  font-size: 12px;
}
.delete {
  text-align: center;
  margin-bottom: 10px !important;
  cursor: pointer;
}
.total {
  background-color: #ecebe4;
  font-size: 12px;
  width: 170px !important;
  border-radius: 10px;
  text-transform: capitalize;
}
.continue {
  font-size: 12px;
  background-color: #a4161bbd !important;
  color: white !important;
  transition: 0.3s;
  width: 140px !important;
}
.continue:hover {
  background-color: #a4161b !important;
}
.counter-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.194);
}
.counter-container2 div {
  cursor: pointer;
}
.counter2 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
}
.cart-ItmBody {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.recipt {
  width: 25%;
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.29);
  padding: 30px;
  margin-right: 10px;
  height: 100%;
}
.recipt p {
  font-size: 13px;
}
.checkout {
  background-color: var(--default-color);
  margin-top: 20px;
  width: 100%;
  color: white;
  font-family: var(--font);
  text-align: center;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  padding: 13px;
  transition: 250ms;
}
.checkout:hover {
  background-color: var(--text-color);
}
@media screen and (max-width: 768px) {
  .pageBody {
    top: 100px;
  }
  .cartItms img {
    width: 60px;
  }
  .ordersummary {
    width: 100%;
  }
  .quantityBox {
    padding: 3px 5px 3px 5px !important;
    width: 90px;
  }
  .cartItms {
    display: flex;
    align-items: center;
    justify-content: center !important;
    margin-top: 30px;
    padding: 0;
  }
  .ordersummary {
    margin-bottom: 50px;
  }
  .recipt {
    width: 100%;
    margin-bottom: 70px;
    margin-right: 0;
    box-shadow: none !important;
  }
}
@media screen and (max-width: 420px) {
  .cartItms img {
    width: 30px;
    height: 30px;
  }
  .cartItms .img .name {
    font-size: 10px !important;
  }
  .counter-container2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 30px;
    padding: 0 10px;
    border-radius: 10px;
    font-size: 16px;
    width: 80px;
    border: 1px solid rgba(0, 0, 0, 0.194);
  }
  .totalPrice {
    font-size: 14px;
  }
}
