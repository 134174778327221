:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --text-color: #181c14;
  --default-color: #a4161bc0;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.product_img {
  width: 120px;
  height: 120px;
  margin-left: 30px;
}

.List {
  position: relative;
  /* border: 1px solid rgba(0, 0, 0, 0.349) !important; */
  box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.05) !important;
  width: 220px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}
.category {
  font-size: 12.25px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.425);
  font-family: inter, sans-serif;
}
.name {
  font-family: inter, sans-serif !important;
  /* font-weight: 600; */
  font-size: 14px;
  width: 170px;
  margin-top: 20px;
}
.price {
  font-size: 15px;
  font-family: inter, sans-serif !important;
}
.addCart {
  border: none;
  background-color: #a4161bc0;
  color: #fff;
  padding: 5px 15px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 20px;
  transition: 0.3s;
  margin-top: 20px;
}
.incart {
  border: none;
  background-color: rgb(20, 20, 20);
  color: #fff;
  padding: 5px 15px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 20px;
  transition: 0.3s;
}
.addCart:hover {
  background-color: #a4161b;
  color: #fff;
}
.Items hr {
  display: none !important;
}
.overlay {
  display: none; /* Initially hidden */
  position: fixed; /* Covers the entire viewport */
  top: 0;
  left: 0;
  z-index: 10000000000;
  width: 100%;
  height: 100%; /* Change to cover full viewport height */
  background-color: rgba(255, 255, 255, 0.9); /* Add slight transparency */
  overflow-y: auto; /* Allows scrolling if content is larger than viewport */
  border-radius: 10px;
  box-shadow: 1px 1px 17px 0px rgba(0, 0, 0, 0.13);
  padding: 30px;
  background-color: white;
}

.overlaydisplay {
  display: block;
}
.bottom {
  margin-top: 50px;
  display: flex;
}
.quantityBox {
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #ecebe4;
  width: 120px !important;
  padding: 5px 10px 5px 10px !important;
  font-size: 14px;
}
.total {
  background-color: #ecebe4;
  padding: 10px 40px !important;
  cursor: pointer;
  transition: 0.2s;
}
.total:hover {
  background-color: #ebe9d0;
}
.cancel {
  background-color: #1c1c1c50;
  border-radius: 100%;
  padding: 5px;
  margin-top: 50px;
  width: 20;
  font-size: 34px;
  cursor: pointer;
}
.cancelBody {
  text-align: end;
}
.overlay img {
  width: 150px;
  height: 150px;
}
.quantityBox button {
  background-color: transparent;
  border: none;
}
.buttons {
  display: flex;
}
.successMessage {
  width: 100%;
  padding: 10px;
  text-align: center;
  color: white !important;
  background-color: green;
  margin-top: 50px;
}
.wishListBtn {
  margin-left: 0 !important;
  font-size: 18px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 20px;
  border: none;
  color: #a4161b;
  padding: 5px 5px 6px 5px;
  transition: 250ms;
  text-transform: capitalize;
  border-radius: 100px;
  width: 40px !important;
  background-color: white;
}
.out-of-stock {
  color: red;
  text-transform: capitalize;
  font-weight: bold;
  font-family: var(--font);
}
@media screen and (max-width: 420px) {
  .quantityBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border: 2px solid #ecebe4;
    width: 170px !important;
    padding: 5px 10px 5px 10px !important;
    font-size: 20px;
    font-size: 16px;
  }

  .cancel {
    margin: 0;
  }
  .out-of-stock {
    font-size: 10px !important;
    width: 80px !important;
  }
}
@media screen and (max-width: 768px) {
  .overlay {
    height: 100%; /* Use full viewport height */
  }
  .buttons {
    display: block;
  }
  .List {
    flex-direction: row;
    position: relative;
    width: 100%;
    align-items: center;
    gap: 30px;
    box-shadow: 1px 1px 17px 0px rgba(0, 0, 0, 0.05) !important;
    justify-content: flex-start;
    height: 150px;
    padding: 20px;
    padding-right: 30px !important;
    border-radius: 20px;
    /* margin-bottom: 70px; */
  }
  .name {
    font-weight: bold;
    font-size: 15px;
  }
  .product_img {
    width: 60px;
    height: 60px;
    margin: 0;
  }
  .items hr {
    display: block !important;
  }
  .com {
    display: flex !important;
    align-items: center;
  }

  .overlay img {
    margin-bottom: 20px;
  }
  .successMessage {
    margin-top: 40px;
    font-size: 10px;
  }
  .addCart {
    border-radius: 10px !important;
    font-size: 14px;
    position: absolute;
    padding: 10px 0;
    right: 10px;
    font-weight: 900 !important;
    width: 100px;
    margin-top: 0;
  }
  .incart {
    border-radius: 10px !important;
    font-size: 14px;
    position: absolute;
    padding: 10px 0;
    right: 10px;
    font-weight: 900 !important;
    width: 100px;
  }
}
@media screen and (max-width: 400px) {
  .wishListBtn {
    right: 20px;
  }
}
