:root {
  --font: "Inter", sans-serif;
  --shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.09);
  --nav-text-color: #233d63;
  --btn-color: #a4161bbd;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
.ContactPage {
  position: relative;
  top: 20px;
}
.contact-information {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 40px;
  padding: 60px 20px;
}
.contact-item {
  width: 500px;
  height: 300px;
  text-align: center;
  padding: 0 40px;
  background-color: white;
}
.Content-head {
  font-weight: 600;
  font-size: 30px;
  margin: 20px 0;
  font-family: var(--font);
  color: #233d63;
}
.content-image {
  width: 110px;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 100px;
}
.content {
  font-weight: 600;
  font-family: var(--font);
  color: #233d63;
  text-decoration: none;
  opacity: 0.7;
}
.customer-review-container {
  padding: 20px 50px;
  display: flex;
  justify-content: space-around;
}
.reviewHeader {
  font-size: 30px;
  margin: 10px 0;
  font-weight: bold;
}
.Review {
  opacity: 0.9;
  color: var(--nav-text-color);
  font-family: var(--font);
}
.review-text {
  font-weight: 500;
  font-size: 18px;
  width: 430px;
  opacity: 0.6;
}
.social-media-icons {
  box-shadow: var(--shadow);
  border-radius: 50px;
  padding: 10px 15px;
  cursor: pointer;
  transition: 250ms;
}
.social-media-icons:hover {
  color: white !important;
  background-color: var(--btn-color);
}
label {
  font-weight: 500;
  margin-top: 10px;
  color: #233d63;
  font-family: var(--font);
}
input {
  border: none;
  outline: none;
  width: 100%;
}
.form-input {
  border: 1px solid #233d631e;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  width: 100%;
}
::placeholder {
  font-family: var(--font);
}
textarea {
  width: 100% !important;
  border: 1px solid #233d631e;
  outline: none;
}
.review-form {
  width: 600px;
  height: 450px;
}
.review-submit-btn {
  padding: 10px 40px;
  transition: 250ms;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  font-family: var(--font);
  background-color: #0000001e;
}
.review-submit-btn:hover {
  background-color: var(--btn-color);
  color: white;
}
@media screen and (max-width: 955px) {
  .contact-information {
    flex-direction: column;
  }
  .customer-review-container {
    flex-direction: column;
  }
  .review-form {
    margin-top: 20px;
  }
}
@media screen and (max-width: 560px) {
  .contact-item {
    width: 400px;
  }
}
@media screen and (max-width: 680px) {
  .review-form {
    width: 100%;
  }
  .review-text {
    width: 100%;
  }
  .customer-review-container {
    padding: 10px 30px;
  }
}
@media screen and (max-width: 440px) {
  .contact-item {
    width: 350px;
  }
}
@media screen and (max-width: 365px) {
  .contact-item {
    width: 320px;
  }
}
